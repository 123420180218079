<template>
    <v-file-input
        :placeholder="config.placeholder" :label="config.label"
        v-model="model[config.key]"
        :required="config.required"
        :maxlength="config.maxlength" :rules="config.rules"
        :error-messages="errorMessages"
        :title="config.label"
        :dense="config.dense"
        :readonly="isReadonly"
        @change="config.change"
    ></v-file-input>
</template>

<script>

export default {
    name: 'FormFile',
    props: ['config', 'model', 'errors'],
    computed: {
        errorMessages() {
            if (this.errors) {
                return this.errors[this.config.key];
            }
            return '';
        },
        isReadonly() {
            return this.config.isReadonly && this.config.isReadonly(this.model);
        }
    }
}
</script>
